import React from 'react';
import {RichText} from 'prismic-reactjs';
import { linkResolver } from '../prismic-configuration.js'

const ColumnBuilder = (props) => {
        console.log(props)

        return (
            <div className={"column-builder "+ props.data.primary.custom_class}>
                <div className="wrapper">
                    <div className="row">
                        {props.data.items.map((column, index)=>{
                            let columnClass = "col-md-"+column.column_width;

                            if(column.column_offset_width > 0) {
                                columnClass += " offset-md-"+column.column_offset_width
                            }
                            if(column.column_pull_width > 0) {
                                columnClass += " pull-md-"+column.column_pull_width
                            }

                            let imageDisplay = column.multi_image.url;

                            switch(column.image_size) {
                                case "landscape":
                                    imageDisplay = column.multi_image.landscape.url;
                                break;
                                case "portrait":
                                    imageDisplay = column.multi_image.portrait.url;
                                break;
                                case "square":
                                    imageDisplay = column.multi_image.square.url;
                                break;
                                default:
                            }

                            return (     
                                <div className={columnClass} key={index}>
                                    {imageDisplay ?
                                    <img src={imageDisplay} alt={column.multi_image.alt} />
                                    : null}
                                    {column.general_title.length && column.general_title[0].text !== "" ?
                                    <RichText render={column.general_title} linkResolver={linkResolver} /> 
                                    : null }
                                    {column.general_text.length && column.general_text[0].text !== "" ?
                                    <RichText render={column.general_text} linkResolver={linkResolver} />
                                    : null }
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            );
}

export default ColumnBuilder;