import React from 'react';
import {ReactComponent as EisoLogoWhite} from '../assets/images/eiso-white-logo.svg';
import {ReactComponent as Close} from '../assets/images/close-button.svg';

const NavigationPanel = (props) => {
        return (
            <div className="wrapper nav-panel">
                    <div className="row">
                        <div className="col-8">
                            <a href="/" className="small-logo"><EisoLogoWhite/></a>
                        </div> 
                        <div className="col-4 text-right">
                            <div className="close" onClick={props.onClick}><Close /></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <ul >
                            {props.navigation.results.map((link, index)=>{
                                    if (link.uid !=="home" && link.uid !=="bio") {
                                        return (
                                            <li key={index}><a href={link.uid}>{link.uid}</a></li>
                                        )
                                    }
                                    else if (link.uid ==="home")  {
                                        return (
                                            <li key={index}><a href="/home">About</a></li>
                                        )
                                    }
                                    else if (link.uid ==="bio")  {
                                        return (
                                            <li key={index}><a href="/bio">Our People</a></li>
                                        )
                                    }
                                    else {
                                        return null
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            );
}

export default NavigationPanel;