import React from 'react';
import {RichText, linkResolver} from 'prismic-reactjs';

const Banner = (props) => {
        
    

        return (
            <div className="banner" style={{backgroundImage: 'url('+props.data.primary.image1.url+')'}}>
                <div className="wrapper">
                    <div className="row align-bottom">
                        <div className="col-md-10">
                            <RichText render={props.data.primary.header} linkResolver={linkResolver} /> 
                        </div>
                    </div>
                </div>
            </div>
            );
}

export default Banner;