// In src/pages/Page.js
import React, { useEffect, useRef, useState,Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { client } from '../prismic-configuration'
import Prismic from 'prismic-javascript'
import Moment from 'moment'
import { ColumnBuilder, Banner, BioBanner, Header, NavigationPanel } from '../components'

const Page = ({ match }) => {
  const [doc, setDocData] = useState(null)
  const uid = match.params.uid
  const [navItems, setNavItems] = useState(null)
  const [menuState, togglemenuState] = useState(false)
  const [sticky, setSticky] = useState("")
  const navPanel = useRef(null)

  let dataSet =[]

  const toggleMenu = () => {
    console.log(navPanel.current)
    if(menuState === true) {
      togglemenuState(false);
      navPanel.current.classList.remove("open");
    }
    else {
      togglemenuState(true)
      navPanel.current.classList.add("open");
    }
  }

  // Get the page document from Prismic
  useEffect(() => {
    document.addEventListener("scroll", e => {
      let scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 60){
        setSticky("sticky")
      } else {
        setSticky("")
      }
    })
    const fetchData = async () => {
      // We are using the function to get a document by its UID
      const result = await client.getByUID('page', uid)
        // console.log(uid)
      if (result) {
        // console.log('doc',result)
        // We use the State hook to save the document
        return setDocData(result)
      } else {
        // Otherwise show an error message
        console.warn('Page document not found. Make sure it exists in your Prismic repository')
      }
    }
    fetchData()
    const fetchNavItems = async () => {
      const response = await client.query(
        Prismic.Predicates.at('document.type', 'page'), { orderings: '[my.page.page_order]'}
      )
      if (response) {
        // console.log('nav: ', response)
        return setNavItems(response)
      }
    }
    fetchNavItems()
   
  }, [uid]) // Skip the Effect hook if the UID hasn't changed

  if (doc && navItems) {
    console.log('page: ', doc)
    doc.data.body.map((module, index) => { 
        switch(module.slice_type) {
          case 'banner':
            dataSet[index] = <Banner data={module} key={index}/>
          break;
          case 'column_builder':
            dataSet[index] = <ColumnBuilder data={module} key={index}/>
          break;
          case 'bio_banner':
            dataSet[index] = <BioBanner data={module} key={index}/>
          break;
          default: 
        }
        return null;
    });
    return (
      <Fragment>
        <Helmet>
          <meta property="og:title" content={doc.data.title[0].text}/>
          <meta property="og:description" content={doc.data.description[0].text} />
          <meta property="og:image" content={doc.data.image.url} />
          <meta property="og:url" content={'https://eiso.co.nz/' + doc.uid} />
          <meta name="twitter:card" content={doc.data.image.url} />
          <meta property="og:site_name" content={doc.data.title[0].text}/>
          <meta name="twitter:image:alt" content={doc.data.description[0].text}/>
          <title>{doc.data.title[0].text}</title>
          <body className={uid==="home" || uid==="bio" ? "" : "header-fix"}></body>
        </Helmet>
        <Header navigation={navItems} currentPage={doc.uid} onClick={toggleMenu} stickyState={sticky} pageStyle={uid==="home"? "" : "dark"} />
        <div className="navigation-panel" ref={navPanel}>
          <NavigationPanel navigation={navItems} onClick={toggleMenu}/>
        </div>
          {dataSet.map((module, index) => {
              return module;
          })}
         <footer className="footer">
            <div className="wrapper">
                <p>© Eiso Group {Moment().format("YYYY")}</p>
            </div>
         </footer>
      </Fragment>
    )
        }
    else {
      return (
        null
      )
    }
}

export default Page