import React from 'react';

const BioBanner = (props) => {
        // console.log(props)
        return (
            <div className="bio-banner" style={{backgroundImage: 'url('+props.data.primary.bio_image.url+')'}}>
                <div className="wrapper">
                    <div className="row align-bottom">
                        <div className="col-md-10">
                            <h2>{props.data.primary.name}</h2>
                            <h2 className="light">{props.data.primary.position}</h2>
                            <h2 className="light">{props.data.primary.company}</h2>
                        </div>
                    </div>
                </div>
            </div>
            );
}

export default BioBanner;