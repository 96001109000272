import React from 'react';
import {ReactComponent as EisoLogo} from '../assets/images/eiso-colour-logo.svg';
import {ReactComponent as EisoLogoWhite} from '../assets/images/eiso-white-logo.svg';
import {ReactComponent as MenuIcon} from '../assets/images/menu.svg'

const Header = (props) => {
    let gradient="";

    if(window.location.href.indexOf('home') >-1) {
        gradient="gradient"
    }
        return (
            <div className={"header " +props.stickyState+" "+props.pageStyle+" "+gradient}>
                <div className="wrapper">
                    <div className="row">
                        <div className="col-md-4">
                            {props.stickyState === "sticky" || props.pageStyle === "dark" ?
                                <a href="/"><EisoLogo/></a>:
                                <a href="/"><EisoLogoWhite/></a>
                            }
                        </div>                       
                        <div className="col-md-8 text-right">
                            <ul className="desktop">
                                {props.navigation.results.map((link, index)=>{
                                    if (link.uid !=="home" && link.uid !=="bio") {
                                        return (
                                            <li key={index}><a href={link.uid}>{link.uid}</a></li>
                                        )
                                    }
                                    else if (link.uid ==="bio")  {
                                        return (
                                            <li key={index}><a href="/home">About</a></li>
                                        )
                                    }
                                    else {
                                        return null
                                    }
                                })}
                            </ul>
                            <div className="menu mobile" onClick={props.onClick}><MenuIcon stroke={'#ffffff'}/></div>
                        </div>
                    </div>
                </div>
            </div>
            );
}

export default Header;